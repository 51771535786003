import React from "react"
import PropTypes from 'prop-types'
function Header(props) {
    return (
        <>
            <div className="preloader">
                <div className="loader">
                    <div className="diamond"></div>
                    <div className="diamond"></div>
                    <div className="diamond"></div>
                </div>
            </div>

            <header className="header">
                <nav className="navbar navbar-expand-md navbar-light fixed-top" id="navigation">
                    <div className="container">
                        <div className="row">
                            <a className="navbar-brand" href="#homepage"><img height="39" src="../assets/images/logo.png" alt="" /></a>
                            <h1>DailyHive</h1>
                        </div>
                        <ul className="navbar-nav ml-auto" id="main-menu">
                            <li className="nav-item"><a className="nav-link active" href="#homepage">Home</a></li>
                            <li className="nav-item"><a className="nav-link" href="#services">Services</a></li>
                            <li className="nav-item"><a className="nav-link" href="#features">Features</a></li>
                            <li className="nav-item"><a className="nav-link" href="#team">Team</a></li>
                            <li className="nav-item"><a className="nav-link" href="#contact">Contact Us</a></li>
                        </ul>

                        <div className="navbar-toggle"></div>
                        <div id="responsive-menu"></div>
                    </div>
                </nav>
            </header>
        </>
    )

}
export default Header