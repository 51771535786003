import React from "react"
import PropTypes from 'prop-types'
function Contact(props) {
    return (
        <> 
        <section className="contactus ripple" id="contact">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="section-title">
						<h2>Contact our <span>Support Team</span></h2>
						<p>We'd love to hear from you.</p>
					</div>
				</div>
			</div>
			
			<div className="row">
				<div className="col-lg-6 offset-lg-3">
					<div className="contact-form">
						<form id="contactForm" action="#" method="post">
							<div className="row">
								<div className="form-group col-md-6 col-sm-6">
									<input type="text" className="form-control" placeholder="Your Name" />
								</div>
								
								<div className="form-group col-md-6 col-sm-6">
									<input type="email" className="form-control" placeholder="Your Email Address" />
								</div>
								
								<div className="form-group col-md-12 col-sm-12">
									<input type="text" className="form-control" placeholder="Subject"/>
								</div>
								
								<div className="form-group col-md-12 col-sm-12">
									<textarea rows="6"className="form-control" placeholder="How can i help you?"></textarea>
								</div>
								
								<div className="col-md-12 col-sm-12 text-center">
									<button type="submit" className="btn-contact">Submit</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			
			<div className="row">
				<div className="col-md-12">
					<div className="copyright">
						<p>Copyright &copy;. All rights reserved. Design and created By <a href="https://www.usibtheteam.com/" target="_blank">USIB The Team</a></p>
					</div>
				</div>
			</div>
		</div>
	</section>
        </>
    )
    
}
   
export default Contact