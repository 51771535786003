import './App.scss';
import Header from './components/header'
import Body from './components/Body'
import Contact from './components/Contact'

const API_URL = 'http://api.usibtheteam.com/api'

function App() {
  return (
    <>
      <Header />
      <Body />
      <Contact/>
    </>
  );
}

export default App;
