import React from "react"
import PropTypes from 'prop-types'
function Body(props) {
    return (
        <> 

	<section className="banner ripple" id="homepage">
		<div className="container">
			<div className="row">
				<div className="col-lg-12">
					<div className="banner-content wow fadeInUp">
						<h2>Start Your Smart Life with <span>DailyHive</span></h2>
						<p>You know the news but don’t know the reason behind it? We have got you covered. <br />We stay behind every news so that you stay ahead in your knowledge.</p>
						
						<div className="btn-header">
							<a href="#homepage">Download Now</a>
							<a className="nav-link" href="#features">See Features</a>
						</div>
					</div>
				</div>
			</div>
		</div>	
	</section>
	
	<section className="services" id="services">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="section-title">
						<h2>Our Awesome <span>Services</span></h2>
						<p>Latest News from your local areas.</p>
					</div>
				</div>
			</div>
			
			<div className="row">
				<div className="col-md-3">
					<div className="service-single wow fadeInUp" data-wow-delay="0.3s">
						<div className="icon-box">
							<i className="flaticon-002-fingerprint-scanning-using-index-finger"></i>
						</div>
						<h3>Easy to Use</h3>
						
						<div className="service-overlay">
							<div className="service-overlay-inner">
								<h4>Easy to Use</h4>
								<p>Simple UI, Not complexly designed</p>
							</div>
						</div>
					</div>
				</div>
				
				<div className="col-md-3">
					<div className="service-single wow fadeInUp" data-wow-delay="0.6s">
						<div className="icon-box">
							<i className="flaticon-006-feature"></i>
						</div>
						<h3>Easy Setup</h3>
						
						<div className="service-overlay">
							<div className="service-overlay-inner">
								<h4>Easy Setup</h4>
								<p>We made creating and managing account simple. </p>
							</div>
						</div>
					</div>
				</div>
				
				<div className="col-md-3">
					<div className="service-single wow fadeInUp" data-wow-delay="0.9s">
						<div className="icon-box">
							<i className="flaticon-004-support"></i>
						</div>
						<h3>Easy Report</h3>
						
						<div className="service-overlay">
							<div className="service-overlay-inner">
								<h4>Easy Report</h4>
								<p>You can report the content.</p>
							</div>
						</div>
					</div>
				</div>
				
				<div className="col-md-3">
					<div className="service-single wow fadeInUp" data-wow-delay="1.2s">
						<div className="icon-box">
							<i className="flaticon-005-customer"></i>
						</div>
						<h3>High Rated Apps</h3>
						
						<div className="service-overlay">
							<div className="service-overlay-inner">
								<h4>High Rated Apps</h4>
								<p>Users are using and finding it useful and helpful.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	
	<section className="features" id="features">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="section-title">
						<h2>Awesome Apps <span>Features</span></h2>
						<p>We offer easy access to every kind of information. Instead of buying a newspaper and adding to the clutter in your bag or on your office desk, it is way easier to open our app.</p>
					</div>
				</div>
			</div>
			
			<div className="row">
				<div className="col-lg-6">
					<div className="feature-single wow fadeInLeft" data-wow-delay="0.3s">
						<div className="icon-box">
							<i className="fa fa-globe"></i>
						</div>
						
						<h3>Change Range</h3>
						<p>You have been given access to expand your news feed range, you can view news from your local area, city, state, or country.</p>
					</div>
					
					<div className="feature-single wow fadeInLeft" data-wow-delay="0.6s">
						<div className="icon-box">
							<i className="fa fa-search"></i>
						</div>
						
						<h3>Filtering Options</h3>
						<p>We provide you simple Filter option which will help you, to find what you're exactly looking for.</p>
					</div>
					
					<div className="feature-single wow fadeInLeft" data-wow-delay="0.9s">
						<div className="icon-box">
							<i className=" fa fa-newspaper-o"></i>
						</div>
						
						<h3>Updates</h3>
						<p>The app will keep you updated with the latest news, We have a push notification option for that.</p>
					</div>
					
					<div className="download-apps">
						<a href="#homepage" className="btn-download">Download Apps</a>
					</div>
				</div>
				
				<div className="col-lg-6">
					<div className="feature-image wow fadeInUp" data-wow-delay="1s">
						<img src="./assets/images/feature.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	</section>
	
	<section className="video-section ripple">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="video-box">
						<a className=""><i className="fa fa-play"></i></a>
						<h4>Watch Application Video</h4>
					</div>
				</div>
			</div>
		</div>
	</section>
	
	<section className="our-team" id="team">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="section-title">
						<h2>Our Expert <span>Team</span></h2>
						<p>This is our crew, Who works hard and smart to provide you this amazing app.</p>
					</div>
				</div>
			</div>
			
			<div className="row">
				<div className="col-lg-3 col-md-6">
					<div className="team-single wow fadeInUp" data-wow-delay="0.3s">
						<figure>
							<img src="./assets/images/team-1.jpg" alt="" />
						</figure>
						
						<div className="team-info">
							<h3>Sadanand Kumar</h3>
							<p>Project Manager</p>
						</div>
					</div>
				</div>
				
				<div className="col-lg-3 col-md-6">
					<div className="team-single wow fadeInUp" data-wow-delay="0.6s">
						<figure>
							<img src="./assets/images/team-2.jpg" alt="" />
						</figure>
						
						<div className="team-info">
							<h3>Satyam Kumar</h3>
							<p>Web/App Developer</p>
						</div>
					</div>
				</div>
				
				<div className="col-lg-3 col-md-6">
					<div className="team-single wow fadeInUp" data-wow-delay="0.9s">
						<figure>
							<img src="./assets/images/team-3.jpg" alt="" />
						</figure>
						
						<div className="team-info">
							<h3>Jaydeep Patel</h3>
							<p>Content Writer</p>
						</div>
					</div>
				</div>
				
				<div className="col-lg-3 col-md-6">
					<div className="team-single wow fadeInUp" data-wow-delay="1.2s">
						<figure>
							<img src="./assets/images/team-4.jpg" alt="" />
						</figure>
						
						<div className="team-info">
							<h3>R. Madhavan</h3>
							<p>UI/UX Designer</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	
	
	<section className="download-app ripple">
		<div className="container">
			<div className="row">
				<div className="col-md-8">
					<div className="download-app-content">
						<h2><span>Download</span> DailyHive today</h2>
						<p>Stay connected on DailyHive, so you can be upto date with your area.</p>
					</div>
				</div>
				
				<div className="col-md-4">
					<div className="download-today">
						<a href="#homepage">Download App</a>
					</div>
				</div>
			</div>
		</div>
	</section>

        </>
    )
    
}
   
export default Body